import React from "react";
import logo from "../../Assets/logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="logo-container">
          <img className="logo" src={logo} alt="Logo" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;


