import React from "react";
import { Link } from "react-router-dom";
import Carreras from "../../Data/Carreras.json";
import "./AllInstitutes.css"; 

const InstitutionList = () => {
  const institutions = [...new Set(Carreras.map((carrera) => carrera.institution))];

  return (
    <div className="institution-list">
      <h1>Instituciones</h1>
      <ul>
        {institutions.map((institution, index) => (
          <li key={index}>
            <Link to={`/instituteDetail?institution=${encodeURIComponent(institution)}`} className="institution-link">
              {institution}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InstitutionList;

