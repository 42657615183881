import React from "react";
import { Link } from "react-router-dom";
import Carreras from "../../Data/Carreras.json";
import "./AllGrade.css"

const AllGrades = () => {
  const grades = [...new Set(Carreras.map((carrera) => carrera.grade))];

  return (
    <div className="grade-list">
      <h1>Grados Académicos</h1>
      <ul>
        {grades.map((grade, index) => (
          <li key={index}>
            <Link to={`/gradeAcademic?grade=${encodeURIComponent(grade)}`} className="grade-link">
              {grade}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AllGrades;

