//La ruleta de las Carreras
import React from "react";
import "./Roulette.css";
import rouletteImg from "../../Assets/ruleta.png";
import rod from "../../Assets/rod-l.png";
import { useState, useEffect } from "react";
import RouletteModal from "../RouletteModal/RouletteModal";
// import rouletteSound from "../../Assets/rouletteSound.mp3"

const Roulette = ({ onClickCell, table, indexTable, modal, setModal }) => {
  const [winner, setWinner] = useState("");
  useEffect(() => {
    const roulette = document.querySelector("#roulette");
    var sound = document.querySelector("#audio");

    roulette.addEventListener("click", function () {
      handleSpin(roulette, sound);
    });
  }, []);

  const spinValue = [];

  var currentValue = 720;
  currentValue = currentValue < 720 ? 720 : currentValue;

  function handleSpin(roulette, sound) {
    let randomNumber = Math.random() * 7200;

    let value = randomNumber;

    value = parseInt(value.toString().split("."));

    value = Math.round(value);

    value = value + currentValue;

    spinValue[0] = value;
    currentValue = spinValue[0];
    var newspinValue = currentValue + "deg";
    var spinNumber = currentValue / 360;

    var winnerNumber = (currentValue % 360) / 45;
    console.log("WinnerNumber: ", winnerNumber);
    winnerNumber = Math.floor(winnerNumber);
    console.log("winnerP:", winnerNumber);

    //cada 45deg cambia de categoria
    // roulette.style.transform="rotate(" + "45deg" + ")";
    roulette.style.transform = "rotate(" + newspinValue + ")";
    // sound.setAttribute('src',rouletteSound);
    setTimeout(() => {
      console.log("Retrasado por 8 segundo.");

      setModal(true);
    }, "6000");

    setWinner(winnerNumber);

    return currentValue;
  }

  return (
    <>
      {modal ? (
        <RouletteModal modal={modal} setModal={setModal} winner={winner} />
      ) : (
        <></>
      )}

      <h2 className="rouletteTitle">Descubre tu camino académico</h2>
      <h5 className="rouletteText">¡Explora nuevas posibilidades!</h5>
      <h5 className="rouletteText">
        Prueba nuestra ruleta y dejate sorprender
      </h5>
      <h5 className="rouletteText">por las opciones que te esperan. </h5>
      <br></br>
      <h5 className="rouletteText">
        <i>Recordá, la ruleta es solamente</i>
      </h5>
      <h5 className="rouletteStrongText">
        <i>
          {" "}
          <strong>una experiencia lúdica.</strong>
        </i>
      </h5>
      <div class="rod">
        {" "}
        <img class="rod-img" src={rod} alt="rod-img" />
      </div>
      <img id="roulette" src={rouletteImg} alt="roulette-img" />
      {/* <div id="sound">
                <iframe src={rouletteSound} id="audio"></iframe>

            </div> */}
    </>
  );
};

export default Roulette;
