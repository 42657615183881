import React from "react";
import { Link } from "react-router-dom";
import artes from "../../Assets/artes.jpg";
import exactas from "../../Assets/exactas.jpg";
import economicas from "../../Assets/economicas.png";
import humanidades from "../../Assets/humanidades.jpg";
import oficios from "../../Assets/oficios.jpg";
import educacion from "../../Assets/educacion.jpg";
import ingenierias from "../../Assets/ingenierias.jpg";
import salud from "../../Assets/salud.jpg";
import "./CategoryComponent.css";

const CategoryListComponent = () => {
  const categories = [
    { name: "Salud", image: salud, link: "/categoryDetail/Salud" },
    { name: "Humanidades", image: humanidades, link: "/categoryDetail/Humanidades" },
    { name: "Oficios", image: oficios, link: "/categoryDetail/Oficios" },
    { name: "Exactas", image: exactas, link: "/categoryDetail/Exactas" },
    { name: "Económicas", image: economicas, link: "/categoryDetail/Económicas" },
    { name: "Artes", image: artes, link: "/categoryDetail/Artes" },
    { name: "Educación", image: educacion, link: "/categoryDetail/Educación" },
    { name: "Ingeniería", image: ingenierias, link: "/categoryDetail/Ingeniería" },
  ];

  return (
    <div className="category-list">
      {categories.map((category, index) => (
        <div className="category-item" key={index}>
          <Link to={category.link}>
            <div className="category-image-container">
              <img src={category.image} alt={category.name} />
            </div>
            <h3>{category.name}</h3>
          </Link>
        </div>
      ))}
    </div>
  );
  
};

export default CategoryListComponent;


