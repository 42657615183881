import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Pin from "../../Assets/PIN.png"

const MapComponent = ({ latitude, longitude }) => {
    useEffect(() => {
      const map = L.map("map").setView([latitude, longitude], 15);
  
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(map);
  
      const customIcon = L.icon({
        iconUrl: Pin,
        iconSize: [32, 32],
      });
  
      const marker = L.marker([latitude, longitude], { icon: customIcon }).addTo(map);
    }, [latitude, longitude]);
  
    return (
      <div id="map" style={{ width: "50%", height: "400px" }}>
       
      </div>
    );
  };
  
  export default MapComponent;
  
