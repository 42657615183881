import React from "react";
import "./AboutUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import Carolina from "../Assets/Carolina.png";
import Ariel from "../Assets/Arielito.png"
import Juli from "../Assets/JULI.png"
import Alexis from "../Assets/ALE.png"
import Luciana from "../Assets/LUCIANA.jpg"


const contributors = [
  { name: "Juliana",  lastname: "Weirich", position: "Coordinadora", image: Juli },
  { name: "Alexis",  lastname: "Ramirez", position: "Product Manager",  image: Alexis },
  { name: "Luciana",  lastname: "Kibisz", position: "Diseñadora Gráfica",  image: Luciana},
  { name: "Carolina",  lastname: "Ramirez", position: "Full Stack Developer",  image: Carolina },
  { name: "Adrián",  lastname: "Laborde", position: "Full Stack Developer", image: Ariel },
];

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us">
        <h1 className="title">¿Qué es Y AHORA?</h1>
        <p className="content">
          Y AHORA? es una plataforma del catálogo actual de carreras e
          instituciones educativas a nivel superior de Oberá, Misiones. Busca
          convertirse en un sitio de referencia, donde cualquier persona pueda
          encontrar en un mismo sitio toda la información para expandir su
          formación superior.
        </p>
        <h1 className="title">¿Quiénes somos?</h1>
        <p className="content">
          Esta aplicación fue impulsada por FUNDACIÓN OVERA y la colaboración
          de:
        </p>

        <div className="contributors">
          {contributors.map((contributor, index) => (
            <div className="contributor" key={index}>
              <img src={contributor.image} alt={contributor.name} className="contributor-img" />
              <div className="contributor-name">{contributor.name}</div>
                <div className="contributor-lastname">{contributor.lastname}</div>
                <div className="contributor-position">{contributor.position}</div>
            </div>
          ))}
        </div>
        <p className="content">
          Esta aplicación no está exenta de errores, por eso te pedimos que nos
          escribas para poder corregir y mejorarla.
         </p>

        <div className="social-links">
          <a
            href="https://instagram.com/fundacionovera?igshid=ZGUzMzM3NWJiOQ=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} className="social-icon" />
          </a>
          <a
            href="https://www.facebook.com/FundacionOvera"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} className="social-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
