import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/LogoNav.png";
import "./NavBar.css";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo-and-menu">
          <Link to="/home">
            <img className="logo" src={logo} alt="Logo" />
          </Link>
          <div
            className={`mobile-menu-button ${isMobileMenuOpen ? "open" : ""}`}
            onClick={toggleMobileMenu}
          >
            &#9776;
          </div>
        </div>
        <div
          className={`nav-links ${
            isMobileMenuOpen ? "mobile-open" : ""
          }`}
        >
          <Link to="/aboutus" className="quiensomos-link">
            Quienes somos
          </Link>
          <Link to="/allinstitutions" className="allinstitutions-link">
            Instituciones
          </Link>
          <Link to="/allgrades" className="gradeacademic-link">
            Grado Académico
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;





