  import React from "react";
  import { useLocation, Link } from "react-router-dom";
  import Carreras from "../../Data/Carreras.json";
  import { useNavigate } from "react-router-dom";
  import "./GradeAcademic.css"

  const GradeAcademic = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedGrade = searchParams.get("grade");

    const filteredCarreras = Carreras.filter(
      (carrera) => carrera.grade === selectedGrade
    );
  
    const navigate = useNavigate();
    const handleCareerClick = (id) => {
      navigate(`/careerDetail/${encodeURIComponent(id)}`);
    };

    return (
      <div className="container-centered">
        <div className="container-shadow">
          <h1 className="title">{selectedGrade}</h1>

          <h2 className="text-center">Carreras Disponibles:</h2>
          {filteredCarreras.map((carrera, index) => (
            <div key={index} onClick={() => handleCareerClick(carrera.id)}>
            <div>
              <h3 className="carrera">{carrera.title}</h3>
            </div>
          </div>
          ))}
        </div>
      </div>
    );
  };

  export default GradeAcademic;


