import React from "react";
import { useLocation } from "react-router-dom";
import Carreras from "../../Data/Carreras.json";
import "bootstrap/dist/css/bootstrap.min.css";
import MapComponent from "./MapComponent";
import { useNavigate } from "react-router-dom";
import "./InstituteComponent.css"

const InstituteDetail = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedInstitution = searchParams.get("institution");

  const filteredCarreras = Carreras.filter(
    (carrera) => carrera.institution === selectedInstitution
  );

  const carreraInfo = Carreras.find(
    (carrera) => carrera.institution === selectedInstitution
  );

  const navigate = useNavigate();

  const handleCareerClick = (id) => {
    navigate(`/careerDetail/${encodeURIComponent(id)}`);
  };

  return (
    <div className="container-centered">
      <div className="container-shadow">
        <h1 className="title">{selectedInstitution}</h1>
        <div className="map-container">
          {carreraInfo && (
            <MapComponent
              latitude={carreraInfo.mapa.latitude}
              longitude={carreraInfo.mapa.longitude}
            />
          )}
        </div>
        <h2 className="text-center">Carreras Disponibles:</h2>
        {filteredCarreras.map((carrera, index) => (
          <div key={index} onClick={() => handleCareerClick(carrera.id)}>
            <div>
              <h3 className="carrera">{carrera.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstituteDetail;
