
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import "./SearchBar.css";
import CarrerasData from "../../Data/Carreras.json";  

const SearchBar = () => {  
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();

    console.log('Término de búsqueda:', searchTerm);
    console.log('CarrerasData:', CarrerasData);

    if (!CarrerasData || !Array.isArray(CarrerasData)) {
      console.log('No se encontraron resultados');
      return;
    }
  
    const resultado = CarrerasData.find((carrera) =>
      carrera.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    if (resultado) {
      console.log('Resultado encontrado:', resultado);
      navigate(`/careerDetail/${resultado.id}`);
    } else {
      console.log('No se encontraron resultados');
    }
  };
  

  return (
    <form className="search-form" onSubmit={handleSearch}>
      <div className="search-bar">
        <input
          className="search-input"
          type="search"
          placeholder="¿Qué Querés Estudiar?"
          value={searchTerm}
          onChange={handleInputChange}
        />
        <button className="search-button" type="submit">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </form>
  );
};

export default SearchBar;




