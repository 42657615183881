import { Link } from "react-router-dom";
import "./RouletteModal.css";
const RouletteModal = ({
  onClickCell,
  table,
  indexTable,
  modal,
  setModal,
  winner,
}) => {
  function handleClose(e) {
    setModal(false);
  }

  function handleTryAgain(e) {
    setModal(false);
  }

  console.log("Winner:", winner);
  switch (winner) {
    case 0:
      winner = "Salud";
      break;

    case 1:
      winner = "Humanidades";
      break;

    case 2:
      winner = "Oficios";
      break;

    case 3:
      winner = "Exactas";
      break;

    case 4:
      winner = "Económicas";
      break;

    case 5:
      winner = "Artes";
      break;

    case 6:
      winner = "Educación";
      break;

    case 7:
      winner = "Ingeniería";
      break;

    default:
      console.log("Lo sentimos, hay un error");
  }

  return (
    <div>
      <div class="modal-dialog" className="overlay">
        <div className="modalContent">
          <div class="row">
            <h1 className="titleModal" id="exampleModalLabel">
              Felicitaciones!!
            </h1>
          </div>
          <div class="modal-body">
            <Link to={`/categoryDetail/${winner}`} className="modalLink">
              <h3 className="modalLink">
                Deberías iniciar una carrera en {winner}
              </h3>
            </Link>
          </div>
          <div className="buttonContainer">
            <h5 class="col" className="modalLink">
              No te gusta esta carrera?
            </h5>
            <button
              button
              type="button"
              className="tryAgainModal"
              onClick={(e) => {
                handleClose(e);
              }}
            >
              Probá otra vez!
            </button>
            <button
              button
              type="button"
              className="closeButtonModal"
              onClick={(e) => {
                handleTryAgain(e);
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouletteModal;
