import React from 'react';
import { useState } from 'react';
import Roulette from "../../Components/Roulette/Roulette"
import CategoryComponent from "../../Components/CategoryComponent/CategoryComponent.jsx"
import  SearchBar from "../../Components/SearchBar/SearchBar"

const Home = () => {
  const [modal, setModal] = useState(false);

  return (
    <div>
      <SearchBar />
     
      <CategoryComponent/>
      <Roulette 
      modal={modal}
      setModal={setModal}/>
    
    </div>
  );
}

export default Home;
