import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Views/Home/Home";
import CareerDetail from "./Components/CareerDetail/CareerDetail.jsx";
import InstituteDetail from "./Components/InstituteComponent/InstituteComponent";
import GradeAcademic from "./Components/GradeAcademic/GradeAcademic";
import NavBar from "./Components/Navbar/NavBar";
import Footer from "./Components/Footer/Footer";
import CategoryDetail from "./Components/CategoryComponent/CategoryDetail";
import Carreras from "../src/Data/Carreras.json";
import AboutUs from "./AboutUs/AboutUs";
import InstitutionList from "./Components/InstituteComponent/AllInstitutes";
import AllGrades from "./Components/GradeAcademic/AllGrade";


function App() {
  return (
    <>
      <NavBar Carreras={Carreras} />
      <Routes>
         <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/careerDetail/:id" element={<CareerDetail />} />
        <Route path="/instituteDetail" element={<InstituteDetail />} />
        <Route path="/gradeAcademic" element={<GradeAcademic />} />
        <Route path="/categoryDetail/:category" element={<CategoryDetail />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/allinstitutions" element={<InstitutionList/>} />
        <Route path="/allgrades" element={<AllGrades/>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

