import React from "react";
import { useParams } from "react-router-dom";
import carreras from "../../Data/Carreras.json";
import { useNavigate } from "react-router-dom";
import "./Detail.css"


const CategoryDetail = () => {
  const { category } = useParams();
  const navigate = useNavigate();

  const categoriasCarreras = {};
  carreras.forEach((carrera) => {
    const { category } = carrera;
    if (!categoriasCarreras[category]) {
      categoriasCarreras[category] = [];
    }
    categoriasCarreras[category].push(carrera);
  });

  const handleCareerClick = (id) => {
    navigate(`/careerDetail/${encodeURIComponent(id)}`);
  };

  return (
    <div className="contenedor-custom">
      <div className="container-shadow-custom">
        <h1 className="title-custom">{category}</h1>

        {categoriasCarreras[category] &&
        categoriasCarreras[category].length > 0 ? (
          categoriasCarreras[category].map((carrera, index) => (
            <div key={carrera.id}  onClick={() => handleCareerClick(carrera.id)}>
              <h2 className="carrera-custom">{carrera.title}</h2>
            </div>
          ))
        ) : (
          <p>No hay carreras disponibles en esta categoría.</p>
        )}
      </div>
    </div>
  );
};

export default CategoryDetail;
