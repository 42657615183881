import React, { useState, useEffect } from "react";
import careersData from "../../Data/Carreras.json";
import favoriteIcon from "../../Assets/star.svg";
import { useParams } from "react-router-dom";
import "./CareerDetail.css";
import favoriteFill from "../../Assets/starFill.svg";
import { FaEnvelope } from "react-icons/fa";
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp } from "react-icons/fa";

const CareerDetail = () => {
  const { id } = useParams();
  const careerId = id;

  const ARTICLE_TITLE = "Qué vas a estudiar?";
  const ARTICLE_URL = "https://www.yahoraobera.com.ar/";

  const singleCareerData = careersData[careerId];

  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const isFavorite = favorites.some((favorite) => favorite.id === careerId);
    setFavorite(isFavorite);
  }, [careerId]);

  const handleFavorite = () => {
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const existingIndex = favorites.findIndex(
      (favorite) => favorite.id === careerId
    );

    if (existingIndex !== -1) {
      // Remove from favorites
      favorites.splice(existingIndex, 1);
      setFavorite(false);
    } else {
      // Add to favorites
      favorites.push(singleCareerData);
      setFavorite(true);
    }

    localStorage.setItem("favorites", JSON.stringify(favorites));
  };

  const handleEmailShare = () => {
    const subject = "¡Mira esta carrera!";
    const body = `Te invito a revisar esta carrera: ${window.location.href}`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };

  const handleClickFacebook = () => {
    const facebookShareUrl = `http://www.facebook.com/sharer/sharer.php?u=${ARTICLE_URL}`;
    window.open(
      facebookShareUrl,
      "facebook_share",
      "height=320, width=640, toolbar=no, menubar=no, scrollbars=yes, resizable=yes, location=no, directories=no, status=no"
    );
  };

  const handleClickTwitter = () => {
    const twitterShareUrl = `http://twitter.com/share?url=${ARTICLE_URL}`;
    window.open(
      twitterShareUrl,
      "twitter_share",
      "height=320, width=640, toolbar=no, menubar=no, scrollbars=yes, resizable=yes, location=no, directories=no, status=no"
    );
  };

  const handleClickLinkedIn = () => {
    const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${ARTICLE_URL}&title=${ARTICLE_TITLE}&summary=&source=`;
    window.open(
      linkedinShareUrl,
      "linkedin_share",
      "height=320, width=640, toolbar=no, menubar=no, scrollbars=yes, resizable=yes, location=no, directories=no, status=no"
    );
  };

  const handleClickWhatsapp = () => {
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=Mira!%20quiero%20estudiar%20esta%20carrera:%20${ARTICLE_URL}`;
    window.open(whatsappShareUrl, "whatsapp_share");
  };

  return (
    <div className="career-detail-container">
      <div className="university-info">
        <h1>
          {singleCareerData.institution}
          <div className="favorite-container">
            <img
              src={favorite ? favoriteFill : favoriteIcon}
              alt="Icono favorito"
              onClick={handleFavorite}
              className="favorite-icon"
            />
          </div>
        </h1>
      </div>
      <div className="career-info">
        <div className="lines-container">
          <div className="lines">
            <div className="white-line">
              <div className="orange-line">
                <div className="description">
                  <table className="info-table">
                    <tbody>
                      <tr>
                        <td className="title">
                          {" "}
                          <strong>Carrera: </strong>
                        </td>
                        <td className="description-text">
                          {singleCareerData.title}
                        </td>
                      </tr>

                      <tr>
                        <td className="title">
                          {" "}
                          <strong>Grado:</strong>{" "}
                        </td>
                        <td className="description-text">
                          {singleCareerData.grade}
                        </td>
                      </tr>

                      <tr>
                        <td className="title">
                          {" "}
                          <strong>Categoría:</strong>{" "}
                        </td>
                        <td className="description-text">
                          {singleCareerData.category}
                        </td>
                      </tr>

                      <tr>
                        <td className="title">
                          {" "}
                          <strong>Nivel:</strong>{" "}
                        </td>

                        <td className="description-text">
                          {singleCareerData.level}
                        </td>
                      </tr>

                      <tr>
                        <td className="title">
                          {" "}
                          <strong>Duración:</strong>{" "}
                        </td>
                        <td className="description-text">
                          {singleCareerData.duration}
                        </td>
                      </tr>

                      <tr>
                        <td className="title">
                          {" "}
                          <strong>Modalidad:</strong>
                        </td>
                        <td className="description-text">
                          {" "}
                          {singleCareerData.mode}
                        </td>
                      </tr>

                      <tr>
                        <td className="title">
                          {" "}
                          <strong>Arancelado/No arancelado:</strong>
                        </td>
                        <td className="description-text">
                          {singleCareerData.access}
                        </td>
                      </tr>

                      <tr>
                        <td className="title">
                          {" "}
                          <strong>Dirección:</strong>{" "}
                        </td>
                        <td className="description-text">
                          {" "}
                          {singleCareerData.address}
                        </td>
                      </tr>

                      <tr>
                        <td className="title">
                          {" "}
                          <strong>Email:</strong>{" "}
                        </td>
                        <td className="description-text">
                          {" "}
                          {singleCareerData.email}
                        </td>
                      </tr>
                      {singleCareerData.phone && (
                        <tr>
                          <td className="title">
                            {" "}
                            <strong>Teléfono:</strong>{" "}
                          </td>
                          <td className="description-text">
                            {" "}
                            {singleCareerData.phone}
                          </td>
                        </tr>
                      )}
                      {singleCareerData.web && (
                        <tr>
                          <td className="title">
                            <strong>Página Web:</strong>{" "}
                          </td>
                          <td className="description-text">
                            <a
                              href={singleCareerData.web}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="webLink"
                            >
                              {singleCareerData.web}
                            </a>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="share-buttons-row">
        <div className="share-fb" onClick={handleClickFacebook}>
          <FaFacebook />
        </div>
        <div className="share-twitter" onClick={handleClickTwitter}>
          <FaTwitter />
        </div>
        <div className="share-linkedin" onClick={handleClickLinkedIn}>
          <FaLinkedin />
        </div>
        <div className="share-whatsapp" onClick={handleClickWhatsapp}>
          <FaWhatsapp />
        </div>
        <div className="share-mail" onClick={handleEmailShare}>
          <FaEnvelope />
        </div>
      </div>
    </div>
  );
};

export default CareerDetail;
